/* Classes */

.App {
    background-color: antiquewhite;
    min-height: 100vh;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(1rem + 3vmin);
    color: white;
}

.App-content {
    margin: auto;
    padding: 2rem;
    max-width: 100rem;
}

a {
    text-decoration: none;
    color: inherit !important;
}

.Login {
    width: 100%;
    max-width: 600px;
    margin: auto;
    margin-top: 100px;
}

.Search {

}

.SearchForm {
    padding: 2rem !important;
    background: white;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    margin-bottom: 2rem !important;
}

.AdItemContainer {
    width: 100%;
    display: flex;
    position: relative;
    margin-bottom: .8rem;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    box-shadow: 1px 1px 8px 1px #e6ebef;
    transition: all .2s ease-in;
}

.AdItemTextContainer {
    padding: 1rem;
    color: black;
}

.AdItemImage {
    width: 20rem;
    height: 15rem;
    object-fit: cover;
}

.price {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
